<template>
  <tr>
    <template v-for="item in items">
      <table-cell
        :key="`col-${paymentMethod?.code}-${item}`"
        :class="getClass(item)"
        :label="getLabel(item)"
      >
        <template v-if="item == 'amount'">
          <!--          <currency-value-field
                      :clearable="fAmount > 0 && !signed"
                      :disabled="signed"
                      :value="value.amount"
                      :currency="sCurrencyCode"
                      :currency-convert="invoiceCurrency?.code"
                      :rate="value.rate"
                      :exchange="obConfig.amount"
                      hide-label
                      @currency:change="setCurrencyCode"
                      @update:exchange="setAmount"
                      @input="setAmount"
                    />-->
          <form-field-text
            :ref="`amountField`"
            :clearable="fAmount > 0 && editable"
            :disabled="!editable"
            :hint="sAmount"
            :persistent-hint="!!sAmount"
            :value="fAmount"
            hide-label
            input-type="number"
            label="amount"
            @input:debounce="setAmount"
          >
            <template
              v-if="amountConvertable && !!invoiceCurrency && !!currency"
              #append
            >
              <v-menu offset-y open-on-hover>
                <template #activator="{ on }">
                  <v-btn small v-on="on">{{ sCurrencyCode }}</v-btn>
                </template>
                <v-list>
                  <v-list-item
                    @click="
                      setCurrencyCode(
                        invoiceCurrency ? invoiceCurrency.code : undefined
                      )
                    "
                  >
                    <v-list-item-title v-text="invoiceCurrency.code" />
                  </v-list-item>
                  <v-list-item
                    @click="
                      setCurrencyCode(currency ? currency.code : undefined)
                    "
                  >
                    <v-list-item-title v-text="currency.code" />
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </form-field-text>
        </template>

        <template v-if="item == 'currency_id'">
          <currency-select
            :disabled="!editable"
            :ids="currencyIds"
            :value="value.currency_id"
            company-only
            hide-label
            @change="onEmit($event, item, 'id')"
          />
        </template>

        <template v-if="item == 'rate'">
          <currency-rate-field
            :disabled="!amountConvertable || !editable"
            :value="value.rate"
            hide-label
            @input:debounce="onEmit($event, item)"
          />
        </template>

        <!-- NUMBER -->
        <template v-if="item == 'number'">
          {{ maskCCNumber(obConfig.number) }}
        </template>

        <template v-if="item === 'account_code'">
          <account-code-select
            v-model="obConfig.account_code"
            :currency-id="value.currency_id"
            :payment-method-code="accountingPMCode"
            hide-label
          />
          <!--          <account-code-preview :code="obConfig.account_code" small />-->
        </template>

        <!-- CONFIG -->
        <template
          v-if="
            arConfigItems.includes(item) &&
            !['number', 'account_code'].includes(item)
          "
        >
          {{ getItemValue(item) }}
        </template>

        <!-- ACTIONS -->
        <template v-if="item == 'actions'">
          <v-btn
            :disabled="!editable && !canUpdateAccountingCode"
            color="red"
            icon
            text
            @click="onDeleteRow"
          >
            <icon-trash size="1.2rem" />
          </v-btn>

          <invoice-payment-method-config-form
            v-if="(arConfigItems.length && !signed) || canUpdateAccountingCode"
            v-model="obConfig"
            :currency-id="value.currency_id"
            :items="arConfigItems"
            :open="open"
            :payment-method="paymentMethod"
          />
        </template>
      </table-cell>
    </template>
  </tr>
</template>

<script lang="ts">
import type {
  InvoicePaymentMethodData,
  PaymentMethod,
} from "@planetadeleste/vue-mc-gw";
import { InvoiceMovementType } from "@planetadeleste/vue-mc-gw";
import { Component, Prop, Vue } from "vue-property-decorator";
import { AppModule } from "@/store/app";
import { InvoiceModule } from "@/store/invoice";
import { currencyConvert, currencyFormat, maskCC } from "@/plugins/helpers";
import { ConfigModule } from "@/store/config";
import type { InvoicePaymentMethodConfig } from "../../mixins/InvoiceMixin";

import CurrencySelect from "@/modules/currencies/components/CurrencySelect.vue";
import InvoicePaymentMethodConfigForm from "@/modules/invoices/components/paymentmethod/InvoicePaymentMethodConfigForm.vue";
import TableCell from "@/components/common/TableCell.vue";
import type { DataTableHeader } from "vuetify";
import {
  assign,
  camelCase,
  filter,
  find,
  get,
  invoke,
  isNaN,
  isNil,
  isObject,
  isUndefined,
  set,
} from "lodash";
import { number } from "mathjs";
import type {
  CurrencyModel,
  InvoiceMovementTypeCode,
  InvoiceTypeCode,
} from "@/types/utils";
import AccountCodePreview from "@/modules/accounts/components/AccountCodePreview.vue";
import { canModuleAccess } from "@/services/moduleAccess";
import AccountCodeSelect from "@/modules/accounts/components/AccountCodeSelect.vue";
import CurrencyRateField from "@/components/form/fields/CurrencyRateField.vue";
import CurrencyValueField from "@/components/form/fields/CurrencyValueField.vue";

@Component({
  components: {
    CurrencyValueField,
    CurrencyRateField,
    AccountCodeSelect,
    AccountCodePreview,
    CurrencySelect,
    InvoicePaymentMethodConfigForm,
    TableCell,
  },
})
export default class InvoicePaymentMethodsRow extends Vue {
  @Prop(Number) readonly maxValue!: number;
  @Prop(Number) readonly index!: number;
  @Prop(Array) readonly items!: string[];
  @Prop(Array) readonly currencyIds!: number[];
  @Prop(Object) readonly value!: InvoicePaymentMethodData;
  @Prop([Array, Object]) readonly headers!: DataTableHeader[];
  @Prop(Object) readonly paymentMethod!: PaymentMethod;
  @Prop(Boolean) readonly open!: boolean;

  sCurrencyCode: string | null = null;

  get sRuleMaxValue() {
    return this.maxValue > 0 ? `max_value:${this.maxValue}` : "";
  }

  /**
   * Display amount in different currency only if currency isn't same of invoice currency
   */
  get sAmount() {
    if (!this.invoiceCurrency || !this.currency || !this.amountConvertable) {
      return null;
    }

    const sCode =
      this.sCurrencyCode == this.invoiceCurrency.code
        ? this.currency.code
        : this.invoiceCurrency.code;
    const fValue =
      this.sCurrencyCode == this.invoiceCurrency.code && this.obConfig.amount
        ? this.obConfig.amount
        : this.value.amount;

    return fValue ? currencyFormat(fValue, sCode) : null;
  }

  get fAmount() {
    return this.invoiceCurrency &&
      this.invoiceCurrency.code !== this.sCurrencyCode
      ? this.obConfig.amount
      : this.value.amount;
  }

  get obConfig(): InvoicePaymentMethodConfig {
    // @ts-ignore
    return this.value.config;
  }

  set obConfig(obData: InvoicePaymentMethodConfig) {
    this.$set(this.value, "config", obData);
  }

  get currency() {
    return this.value.currency_id
      ? AppModule.currencies.find({ id: this.value.currency_id })
      : null;
  }

  get invoiceCurrency() {
    const iCurrencyId = this.obInvoice.get("currency_id");
    if (!iCurrencyId) {
      return undefined;
    }

    return AppModule.currencies.find({ id: iCurrencyId });
  }

  get obInvoice() {
    return InvoiceModule.invoice;
  }

  get signed(): boolean {
    return InvoiceModule.signed || InvoiceModule.isSigning;
  }

  /**
   * Get true if invoice is received
   *
   * @returns {boolean}
   */
  get isReceived(): boolean {
    return InvoiceModule.isReceived;
  }

  get iMovementType(): InvoiceMovementTypeCode {
    return InvoiceModule.iMovementType;
  }

  get sTypeCode(): InvoiceTypeCode {
    return number(InvoiceModule.sTypeCode);
  }

  /**
   * Get true if invoice is e-Recibo type
   */
  get isReceiptType(): boolean {
    return !!this.sTypeCode && this.sTypeCode === 701;
  }

  /**
   * Get true if invoice is type VENTA
   */
  get isSales(): boolean {
    const arMovementTypeList = [
      InvoiceMovementType.CODE_SALES,
      InvoiceMovementType.CODE_DEBIT_NOTE,
    ];
    return arMovementTypeList.includes(this.iMovementType);
  }

  get isProcessed(): boolean {
    return this.obInvoice.get("is_processed", true);
  }

  get canUpdateAccountingCode() {
    return (
      (this.isReceiptType || this.isReceived || this.isSales) &&
      canModuleAccess("accounting.entries")
    );
  }

  get editable() {
    return !this.signed || this.isReceived;
  }

  get amountConvertable() {
    return (
      !!this.currency &&
      !!this.invoiceCurrency &&
      this.invoiceCurrency.code !== this.currency.code
    );
  }

  get arConfigItems() {
    return filter(this.items, (sKey) => {
      return !["amount", "rate", "currency_id", "actions"].includes(sKey);
    });
  }

  get bankId() {
    if (!this.obConfig.bank_name || isNil(this.obConfig.bank_name)) {
      return null;
    }

    const sBank = this.obConfig.bank_name as any;
    const obFindData = isNaN(sBank) ? { name: sBank } : { id: number(sBank) };
    const obBank = AppModule.banks.find(obFindData);
    return obBank ? obBank.id : null;
  }

  set bankId(sValue: number | null) {
    if (!sValue) {
      return;
    }

    const obBank = AppModule.banks.find({ id: sValue });
    if (obBank) {
      set(this.obConfig, "bank_name", obBank.name);
    }
  }

  get sBankName() {
    const sBank = this.obConfig.bank_name as any;

    if (sBank && isNaN(sBank)) {
      return sBank;
    }

    if (!isNaN(sBank) && !isNil(sBank)) {
      const obBank = AppModule.banks.find({ id: number(sBank) });
      return obBank ? obBank.name : undefined;
    }

    return undefined;
  }

  /**
   * Get payment method code for accounting code.
   * If pm is type of check, change code to received or emitted if invoice is for sales
   */
  get accountingPMCode() {
    const sCode = this.paymentMethod?.get("code") as string | undefined;
    if (!sCode) {
      return undefined;
    }

    if (sCode !== "CHEQUE") {
      return sCode;
    }

    return this.isSales ? "CHEQUE_RECIBIDO" : "CHEQUE_EMITIDO";
  }

  mounted() {
    this.$nextTick(() => {
      if (this.value.currency_id) {
        const obCurrency = AppModule.currencies.find({
          id: this.value.currency_id,
        });
        if (obCurrency) {
          this.sCurrencyCode = obCurrency.code;
        }
      } else if (this.invoiceCurrency?.code) {
        this.sCurrencyCode = this.invoiceCurrency.code;
        this.onEmit(this.invoiceCurrency.id, "currency_id");
      }
    });
  }

  onDeleteRow() {
    this.$emit("delete", this.index);
  }

  onEmit(sValue: any, sKey: string, sObjectKey?: string, bInvoke = true) {
    if (isObject(sValue) && sObjectKey) {
      sValue = get(sValue, sObjectKey);
    }

    this.$emit("update:item", [this.index, sKey, sValue]);

    if (bInvoke) {
      const sMethod = camelCase(`set_${sKey}`);
      invoke(this, sMethod, sValue);
    }
  }

  setCurrencyId(sValue: number) {
    let obCurrency = null;
    let fRate = 0;

    if (ConfigModule.companyCurrencyRates.length) {
      obCurrency = ConfigModule.companyCurrencyRates.find({
        currency_id: sValue,
      });
      if (obCurrency) {
        fRate = Number(obCurrency.rate);
        this.sCurrencyCode = obCurrency.currency.code;
      }
    }

    if (!fRate) {
      obCurrency = AppModule.currencies.find({ id: sValue }) as
        | CurrencyModel
        | undefined;
      if (obCurrency) {
        fRate = obCurrency.rate_value;
        this.sCurrencyCode = obCurrency.code;
      }
    }

    if (fRate && fRate !== 1 && fRate !== 1.0) {
      this.onEmit(fRate, "rate", undefined, false);

      if (this.amountConvertable) {
        this.convert(this.value.amount).then((fVal) => {
          this.obConfig = assign(this.obConfig, { amount: fVal });
        });
      }
    }

    // Reset account code on change currency
    if (this.obConfig.account_code) {
      this.$set(this.obConfig, "account_code", null);
    }
  }

  setRate() {
    if (this.amountConvertable && this.invoiceCurrency && this.currency) {
      const bIsInvoiceCurrency =
        this.sCurrencyCode == this.invoiceCurrency.code;
      const fValue = bIsInvoiceCurrency
        ? this.value.amount
        : this.obConfig.amount;

      this.setAmount(fValue);
    }
  }

  setCurrencyCode(sValue?: string) {
    if (!sValue) {
      return;
    }

    this.sCurrencyCode = sValue;
  }

  setAmount(fValue: number) {
    if (isNil(fValue) || isNaN(fValue)) {
      fValue = 0;
    }

    if (this.amountConvertable && this.invoiceCurrency && this.currency) {
      const bInvert = this.sCurrencyCode == "UYU";
      const bIsInvoiceCurrency =
        this.sCurrencyCode == this.invoiceCurrency.code;

      // Check if is editing main currency or selected
      if (bIsInvoiceCurrency) {
        this.value.amount = fValue;
      } else {
        this.obConfig = assign(this.obConfig, { amount: fValue });
      }

      this.convert(fValue, bInvert).then((fVal) => {
        if (bIsInvoiceCurrency) {
          this.obConfig = assign(this.obConfig, { amount: fVal });
        } else {
          this.value.amount = fVal;
        }
      });
    } else {
      this.value.amount = fValue;
    }
  }

  getClass(sValue: string) {
    const obHeaderData = find(this.headers, { value: sValue });
    return obHeaderData ? obHeaderData.class : undefined;
  }

  getLabel(sValue: string) {
    const obHeaderData = find(this.headers, { value: sValue });
    return obHeaderData ? obHeaderData.text : undefined;
  }

  getItemValue(sKey: string) {
    if (sKey === "bank_name") {
      return this.sBankName;
    }

    return get(this.obConfig, sKey);
  }

  maskCCNumber(sValue?: string) {
    return sValue ? maskCC(sValue) : null;
  }

  async convert(fAmount: number, bInvert?: boolean): Promise<number> {
    if (!this.amountConvertable) {
      return fAmount;
    }

    if (this.invoiceCurrency && this.currency) {
      const sCode =
        this.invoiceCurrency.code === "UYU"
          ? this.currency.code
          : this.invoiceCurrency.code;

      if (isUndefined(bInvert)) {
        bInvert = this.invoiceCurrency.code === "UYU";
      }

      const obConvert = await currencyConvert(
        fAmount,
        sCode,
        this.value.rate,
        bInvert
      );

      if (obConvert) {
        return obConvert.value;
      }
    }

    return fAmount;
  }
}
</script>
