<template>
  <form-field-text
    :disabled="disabled"
    :hide-label="hideLabel"
    :value="fRate"
    input-type="number"
    label="rate"
    @input:debounce="onInput"
  />
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { number } from "mathjs";

@Component
export default class CurrencyRateField extends Vue {
  @VModel({ type: [String, Number], default: "0" }) rate!: string | number;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly hideLabel!: boolean;

  get fRate(): number {
    const fValue = number(this.rate);
    return !fValue || fValue === 1 || fValue === 1.0 ? 0 : fValue;
  }

  onInput(fValue: string | number) {
    fValue = number(fValue);

    if (!fValue) {
      fValue = 1;
    }

    this.$emit("input", fValue);
    this.$emit("input:debounce", fValue);
  }
}
</script>
